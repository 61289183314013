import * as React from "react"
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import Header from '../components/header'
import StickyNav from '../components/stickynav'
import Flush from '../components/flush'
import CardCol from '../components/card'
import Footer from '../components/footer'

import "animate.css/animate.min.css";
import '../components/font.css';
import '../components/stylesheet.css';

import mockup from "../images/1m-workshop/1mws-top-img.png"
import sketch from "../images/sketch-bgwhite.svg"
import illustrator from "../images/ai-bgwhite.svg"
import zeplin from "../images/zeplin-bgwhite.svg"
import phone from "../images/icon-phone.svg"
import ad from "../images/icon-ad.svg"
import globe from "../images/icon-globe.svg"
import dgGrid from "../images/1m-workshop/1mws-dg-grid.png"
import dg from "../images/1m-workshop/1mws-dg.png"
import detail1 from "../images/1m-workshop/details-content-1.png"
import detail2 from "../images/1m-workshop/details-content-2.png"
import detail3 from "../images/1m-workshop/details-content-3.png"
import detail4 from "../images/1m-workshop/details-content-4.png"

import card5678 from "../images/5678-card-img.png"
import card1mws from "../images/1mws-card-img.png"
import card1mhd from "../images/homedance-card-img.png"


const itemLink = [
  {text: "소개", destination: "#top",},
  {text: "기능", destination: "#feature",},
  {text: "디자인 가이드", destination: "#designGuide",},
  {text: "후기", destination: "#review",},
]

const sftwrList = [
  {name: "sketch", src: sketch},
  {name: "illustrator", src: illustrator},
  {name: "zeplin", src: zeplin}
]

// markup
const Project2 = () => {
  return (
    <main lang="ko" className="m-0">
      <title>1M Workshop</title>
      <Header />
      <StickyNav href="/1m-workshop" bgColor="black" textColor="white" title="1M Workshop" itemArr={ itemLink }/>
      <div className="scrollspy bg-black text-white" id="omws"
      data-bs-spy="scroll"
      data-bs-target="#stickynav"
      data-bs-offset="64">
        <div className="px-4 text-center" id="top">
            <AnimationOnScroll animateIn="animate__fadeIn">
            <h1 className="display-title fw-700 pb-3 mb-4 pt-5" lang="en">1M Workshop</h1>
            <img src={ mockup } width="94%" className="mb-4" />
            </AnimationOnScroll>
          <div className="mx-lg-15p">
            <AnimationOnScroll animateIn="animate__fadeIn">
            <p className="fs-1 fw-700 mt-5 lh-1">워크샵 소개부터 예약까지</p>
            <p className="fs-1 fw-700 yop-gradient pb-2 mx-auto lh-1" lang="en">All-in-1.</p>
            <p className="fs-5 fw-600 lh-base w-md-50 mx-auto px-md-5 text-center">
            어떤 워크샵 이벤트에서도 사용할 수 있는 <span lang="en">Universal All-in-1</span> 웹사이트 
            템플릿으로 구글 폼, 계좌이체 등 여러 서비스 사용하던 기존의 워크샵 
            예약 <span lang="en">FLOW</span>를 보완한 <span lang="en">1-Paged</span> 웹사이트를 소개합니다.
            </p>
            <a href='https://xd.adobe.com/view/2a9ddfb1-d7b5-441c-afed-d1af7c7d2a10-7fce/?fullscreen'  target="_blank">
              <button
              className="yop-gradient-border mb-5">프로토타입 보기 
              <span lang="en" className="fw-400"> 􀆊</span></button>
            </a>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__fadeIn">
              <div className="mx-lg-5">
              <Flush
              team="PO, 2 Front-End Developers, 2 Back-End Developers"
              contribute="Overall 100%  |  UI/UX Design 100%"
              role="Product Owner / Project Owner"
              roleDscrpt="서비스 기획, 브랜딩 구축, 와이어프레임, 플로우 차트, 마케터 & 콘텐츠 프로듀서와 마케팅 방향 구축, 스크럼팀 운영/리드"
              softwares={ sftwrList }></Flush>
              </div>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__fadeIn">
              <Row xs={1} lg={3} className="g-4 mt-5">
                <Col>
                    <Card className="px-4 pt-5 pb-4 h-100 bg-212121 text-start">
                        <Card.Img variant="top" src={ phone } className="pb-4 mx-1" id="icon"/>
                        <Card.Body className="text-start p-0 mx-1">
                        <Card.Title className="fw-700 w-75">디바이스 특성을 분석한 <span lang="en">Data-Driven</span> 디자인.</Card.Title>
                        <Card.Text className="fw-500">워크숍 타켓은 원밀리언 사용자와 동일하기에 원밀리언 수강생들의 디바이스 
                        사용 환경을 분석해 디자인을 했습니다.
                        <br></br>대다수의 원밀리언 사용자들은 수업 일정 확인부터 예약까지 모바일 디바이스를 
                        사용한다는 점을 고려해 사용자 데이터를 기반으로 모바일 <span lang="en">􀆊</span> 태블릿 <span lang="en">􀆊 PC</span> 순의 디자인.</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card className="px-4 pt-5 pb-4 h-100 bg-212121 text-start">
                        <Card.Img variant="top" src={ ad } className="pb-4 mx-1" id="icon"/>
                        <Card.Body className="text-start p-0 mx-1 pt-3">
                        <Card.Title className="fw-700 w-75">홍보 / 마케팅 방향까지 고려한 디자인.</Card.Title>
                        <Card.Text className="fw-500">댄서들은 수업 홍보를 위해 인스타그램을 활용합니다. 
                        <span lang="en">URL</span> 링크를 올리고 학생들이 그 링크를 통해 예약하게 유도합니다. 또한 인쇄 포스터 내 
                        <span lang="en">QR</span>코드를 통해서도 예약을 유도합니다. 이 플로우를 
                        <span lang="en">PV</span>와 전화률 상승으로 연결하기 위한 모바일 우선 디자인.</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card className="px-4 pt-5 pb-4 h-100 bg-212121 text-start">
                        <Card.Img variant="top" src={ globe } className="pb-4 mx-1" id="icon"/>
                        <Card.Body className="text-start p-0 mx-1 pt-2">
                        <Card.Title className="fw-700 w-75">브랜딩이 없는게 브랜딩. <span lang="en">Universal </span> 디자인.</Card.Title>
                        <Card.Text className="fw-500">각 워크숍의 브랜딩은 진행하는 안무가의 개성이 들어가는 잔뜩 들어갑니다. 
                        모든 브랜딩을 아우를 수 있는 <span lang="en">Black & White</span> 테마의 웹사이트.<br></br>
                        <span lang="en">PC</span>, 태블릿, 모바일 서비스에 대한 경험의 차이를 최소화하고 경험의 만족도를 높이는 콘텐츠를 구성.</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
              </Row>
            </AnimationOnScroll>
          </div>
        </div>
        <div className="px-4 pt-5 mx-lg-15p" id="feature">
          <AnimationOnScroll animateIn="animate__fadeIn">
          <div className="text-start">
            <p className="m-0 fs-6 fw-600 fc-gray" lang="en">Features</p>
            <p className="fw-section w-md-60 fw-700">호환성. 플로우. 전환률<br></br>두루두루 탁월한.</p>
          </div>
          </AnimationOnScroll>
          <div className="mt-5 mx-lg-5e">
          <AnimationOnScroll animateIn="animate__fadeInLeft">
            <Row xs={1} lg={2} className="g-0 mt-4">
              <Col lg={8}>
                <div className="text-start">
                  <img src={ detail1 } width="100%" />
                </div>
              </Col>
            </Row>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__fadeInRight">
            <Row xs={1} lg={2} className="g-0 mt-2 mt-lg-n3">
              <Col lg={4}></Col>
              <Col lg={8}>
                <div className="text-end">
                  <img src={ detail2 } width="100%" />
                </div>
              </Col>
            </Row>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__fadeInLeft">
            <Row xs={1} lg={2} className="g-0 mt-2 mt-lg-n3">
              <Col lg={8}>
                <div className="text-start">
                  <img src={ detail3 } width="100%" />
                </div>
              </Col>
            </Row>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__fadeInRight">
            <Row xs={1} lg={2} className="g-0 mt-2 mt-lg-n3">
              <Col lg={4}></Col>
              <Col lg={8}>
                <div className="text-end">
                  <img src={ detail4 } width="100%" />
                </div>
              </Col>
            </Row>
          </AnimationOnScroll>
          </div>
        </div>
        <div className="px-4 pt-5 bg-start-25" id="designGuide">
          <AnimationOnScroll animateIn="animate__fadeIn">
            <div className="text-start mx-lg-15p">
              <p className="m-0 fs-6 fw-600 fc-gray" lang="en">Design Guide</p>
              <p className="fw-section w-md-60 fw-700">오직 <sapn lang="en">Black & White.</sapn><br></br>대담하게. 모던하게.</p>
            </div>
              <div className="text-center mt-0">
                <img src={ dgGrid } width="100%" className="mb-4 w-lg-80" />
                <img src={ dg } width="100%" className="px-5 mx-lg-auto my-4 w-lg-80" />
              </div>
            </AnimationOnScroll>
        </div>
        
        <div className="px-4 py-5 mx-lg-15p" id="review">
          <AnimationOnScroll animateIn="animate__fadeIn">
            <div className="text-start mt-5">
              <p className="m-0 fs-6 fw-600 fc-gray" lang="en">Review</p>
              <p className="fw-section w-md-60 fw-700">프로젝트를 마무리하며</p>
            </div>
            <p className="my-3 my-lg-5">기획부터 개발 완료까지 약 2주라는 시간밖에 주어지지 않아서 과정을 어디에서부터 시작해야 할지 
              고민이 많았다. 게다가 새로 입사한 개발자들과 처음으로 함께 일하는 프로젝트여서 개발 기간 또한 
              가늠하기 어려운 상황이어서 기획과 디자인에 어느 정도의 시간을 할애할 수 있는지 걱정이 많았다. 
              그래도 허투루 넘어가는 과정 없이 차근차근 진행하기 위해 짧은 스프린트를 진행하기로 했다. 
              이 프로젝트 말고 내가 맡고 있던 메인 프로젝트(이 프로젝트보다 규모가 훨씬 컸다.) 에서도 스크럼을 
              운영하고 스프린트를 진행하기는 했지만, 체계가 잡히지 않은 상태에서 운영이 쉽지 않았다. 
              이 웹사이트를 제작할 때는 그간의 경험을 토대로 과정까지 꼼꼼히 살피는 스프린트를 진행하기 위해 
              노력했다. 또한 함께 일한 팀원들도 애자일 방식을 받아들이고 함께 노력해줘서 더 좋은 과정이었다. 
              완벽한 스프린트였다고 할 수는 없지만, 팀의 규모나 기간을 생각했을 때, 상황에 딱 맞는 스프린트 
              과정이었다고 자부할 수 있다.
            </p>
            <p className="my-3 my-lg-5">인하우스 PO로 일하면서 클라이언트를 만날 일은 없다. 그래서 클라이언트의 요구사항을 토대로 서비스를 
              만드는 경험이 부족했다. 이 프로젝트도 인하우스 프로젝트였지만 아티스트 관리를 담당하는 엔이전시팀이 
              워크샵을 담당하면서 클라이언트가 되었다. 처음으로 클라이언트와 소통하는 디자인 과정을 경험했다. 
              PO로서 서비스 기획/디자인만큼 많은 소통과 조율을 했다. 에이전시팀과 개발팀 사이에서 의견을 조율하고 
              빠르게 바뀌는 상황을 파악하고 그에 따라 기획을 수정하고 전달하는 과정이 많았다. 정말 힘들었다. 
              그래도 해야하기 때문에 열심히 했고 마지막에 마지막까지 잘했다. 
              첫 번째 워크샵이였던 Sori Na's Throwback이 성공했다는 축하를 받았기 때문에 잘했다고 당당히 
              말할 수 있다.
            </p>
          </AnimationOnScroll>
        </div>
      </div>
      <div className="pt-4 pb-4 mx-0 mb-5 text-center bg-white" id="case-study">
        <AnimationOnScroll animateIn="animate__fadeIn" animateOut="animate__fadeOut">
        <h6 className="fw-600 mt-5" lang="en">Case Study</h6>
        <h1 className="display-3 fw-700 pb-3 blue-gradient">이제는 증명할 시간.</h1>
        <p className="mx-5 mb-0 fw-600">아래에서 마음에 드는 것을 골라 탐구해보세요.</p>
        <p className="mx-5 fw-600">신규 서비스 기획/디자인부터 스크럼 팀 리드까지 저의 작업 과정, 스타일 등을 심층 탐구 할 수 있습니다.</p>
        <Row xs={1} md={3} className="g-4 mt-4">
        <CardCol link="/5six7eight" imgSrc={ card5678 } title="5six7eight Creative Studio"
        text="5678에서 수업을 진행하는 안무가들이 걱정없이 수업에 집중할 수 있게 관리해주고 최적의 온라인 수업 환경을 제공합니다." />
        <CardCol link="/1m-workshop" imgSrc={ card1mws } title="1M Workshop"
        text="기존의 워크샵 예약 FLOW를 보완한 어떤 워크샵 이벤트에서도 사용할 수 있는 Universal All-in-1 웹사이트를 소개합니다." />
        <CardCol link="/1m-homedance" imgSrc={ card1mhd } title="1M HomeDance"
        text="5678에서 수업을 진행하는 안무가들이 걱정없이 수업에 집중할 수 있게 관리해주고 최적의 온라인 수업 환경을 제공합니다." />
        </Row>
        </AnimationOnScroll>
      </div>
      <Footer bgColor="black" textColor="white" />
    </main>
  )
}

export default Project2
