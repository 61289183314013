import * as React from "react"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Flush({ team, contribute, role, roleDscrpt, softwares }) {
  return (
    <Container className="mt-5 mx-auto p-0" id="flush">
        <div className="border-top border-bottom py-5 mx-auto">
            <Row className="text-start ms-0 me-5">
                <Col xs={6} md={4} className="fw-700 mb-2">
                    팀
                </Col>
                <Col xs={12} md={8} className="ps-md-5" lang="en">
                    <p className="fw-400 w-lg-80">{ team }</p>
                </Col>
            </Row>
        </div>
        <div className="border-bottom py-5 mx-auto">
            <Row className="text-start ms-0 me-5">
                <Col xs={6} md={4} className="fw-700 mb-2">
                    기여도
                </Col>
                <Col xs={12} md={8} className="ps-md-5" lang="en">
                    { contribute }
                </Col>
            </Row>
        </div>
        <div className="border-bottom py-5 mx-auto">
            <Row className="text-start ms-0 me-5">
                <Col xs={6} md={4} className="fw-700 mb-2">
                    역할
                </Col>
                <Col xs={12} md={8} className="ps-md-5">
                    <p className="fw-700 lh-sm" lang="en">{ role }</p>
                    <p className="fw-500 w-lg-80">{ roleDscrpt }</p>
                </Col>
            </Row>
        </div>
        <div className="border-bottom py-5 mx-auto">
            <Row className="text-start ms-0 me-5">
                <Col xs={6} md={4} className="fw-700 mb-2">
                    소프트웨어
                </Col>
                <Col xs={12} md={8} className="ps-md-5">
                    <span>
                        {softwares && softwares.map((link, i) => (
                        <React.Fragment key={link.name}>
                            <img src={ link.src } alt={ link.name } className="me-2" width="36px;" />
                        {i !== softwares.length - 1}
                        </React.Fragment>
                        ))}
                    </span>
                </Col>
            </Row>
        </div>
    </Container>
  );
}

export default Flush;